* {
  margin: 0;
  padding: 0;
  color: white;
}

html,
body {
  overflow: hidden;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: Helvetica;
}

.link-icons {
  height: 8vh;
  width: 100vw;
  text-align: center;
  vertical-align: bottom;
  display: table-cell;
}

.link-icons .link-icon {
  height: clamp(20px, 8vh, 50px);
  width: clamp(20px, 8vh, 50px);
  margin-left: 3vw;
  margin-right: 3vw;
}

.day-night-field {
  position: absolute;
  bottom: 5%;
  width: 100%;
}

.day-night-field .day-night-button {
  cursor: pointer;
  text-align: center;
  margin: auto;
}

.day-night-field .day-night-button .day-night-icon {
  height: clamp(15px, 2vh, 100px);
  width: clamp(15px, 2vh, 100px);
}
